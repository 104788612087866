// const dev = {
//     backendUrl: "http://46.101.53.121:8083",
//     portalUrl: "http://46.101.53.121:40030"
// }

const dev = {
  imageBasePath: "http://localhost:3001",
  backendUrl: "http://localhost:3001", // DEBUG
  portalUrl: "http://localhost:40031", // DEBUG
  menuLinks: [
    {
      href: "http://46.101.53.121:40017/",
      src: "https://membre-server.leadersante-groupe.fr/img/tabord.png",
    },
    {
      href: "https://play.deepidoo.com/user/sign_in",
      src: "https://membre-server.leadersante-groupe.fr/img/myRadio.png",
    },
    {
      href: "https://myprint.webtoprint-by-pnr.com/",
      src: "https://membre-server.leadersante-groupe.fr/img/myPrint.png",
    },
    {
      href: "https://membre.leadersante-groupe.fr/application/myPrivilege",
      src: "https://membre-server.leadersante-groupe.fr/img/myPrivilege.png",
    },
    {
      href: "http://mystat.ospharm.org/",
      src: "https://membre-server.leadersante-groupe.fr/img/myStat.png",
    },
    {
      href: "https://www.facebook.com/groups/116447895659424",
      src: "https://membre-server.leadersante-groupe.fr/img/myBourse.png",
    },
    {
      href: "http://46.101.53.121:40098/",
      src: "https://membre-server.leadersante-groupe.fr/img/myTrade.png",
    },
    {
      href: "https://grippometre.ospharm.org",
      src: "https://membre-server.leadersante-groupe.fr/img/grippometre.png",
    },
    {
      href: "https://membre.leadersante-groupe.fr/book",
      src: "https://membre-server.leadersante-groupe.fr/img/book.png",
    },
  ],
};

const staging = {
  imageBasePath:
    "https://res.cloudinary.com/leadersante16/image/fetch/e_trim/http://staging-shop-depot-server.leadersante-groupe.fr",
  backendUrl: "https://staging-shop-depot-server.leadersante-groupe.fr",
  portalUrl: "https://staging-membre.leadersante-groupe.fr",
  menuLinks: [
    {
      href: "https://staging-tabord.leadersante-groupe.fr",
      src: "https://membre-server.leadersante-groupe.fr/img/tabord.png",
    },
    {
      href: "https://play.deepidoo.com/user/sign_in",
      src: "https://membre-server.leadersante-groupe.fr/img/myRadio.png",
    },
    {
      href: "https://myprint.webtoprint-by-pnr.com/",
      src: "https://membre-server.leadersante-groupe.fr/img/myPrint.png",
    },
    {
      href: "https://staging-membre.leadersante-groupe.fr/application/myPrivilege",
      src: "https://membre-server.leadersante-groupe.fr/img/myPrivilege.png",
    },
    {
      href: "http://mystat.ospharm.org/",
      src: "https://membre-server.leadersante-groupe.fr/img/myStat.png",
    },
    {
      href: "http://leadersantemybourse.fr/login",
      src: "https://membre-server.leadersante-groupe.fr/img/myBourse.png",
    },
    {
      href: "https://staging-mytrade.leadersante-groupe.fr/",
      src: "https://membre-server.leadersante-groupe.fr/img/myTrade.png",
    },
    {
      href: "https://grippometre.ospharm.org",
      src: "https://membre-server.leadersante-groupe.fr/img/grippometre.png",
    },
    {
      href: "https://staging-membre.leadersante-groupe.fr/books",
      src: "https://membre-server.leadersante-groupe.fr/img/book.png",
    },
  ],
};

const production = {
  imageBasePath:
    "https://res.cloudinary.com/leadersante16/image/fetch/e_trim/http://shop-depot-server.leadersante-groupe.fr",
  backendUrl: "https://shop-depot-server.leadersante-groupe.fr",
  portalUrl: "https://membre.leadersante-groupe.fr",
  menuLinks: [
    {
      href: "https://tabord.leadersante-groupe.fr",
      src: "https://membre-server.leadersante-groupe.fr/img/tabord.png",
    },
    {
      href: "https://play.deepidoo.com/user/sign_in",
      src: "https://membre-server.leadersante-groupe.fr/img/myRadio.png",
    },
    {
      href: "https://myprint.webtoprint-by-pnr.com/",
      src: "https://membre-server.leadersante-groupe.fr/img/myPrint.png",
    },
    {
      href: "https://membre.leadersante-groupe.fr/application/myPrivilege",
      src: "https://membre-server.leadersante-groupe.fr/img/myPrivilege.png",
    },
    {
      href: "http://mystat.ospharm.org/",
      src: "https://membre-server.leadersante-groupe.fr/img/myStat.png",
    },
    {
      href: "http://leadersantemybourse.fr/login",
      src: "https://membre-server.leadersante-groupe.fr/img/myBourse.png",
    },
    {
      href: "https://mytrade.leadersante-groupe.fr/",
      src: "https://membre-server.leadersante-groupe.fr/img/myTrade.png",
    },
    {
      href: "https://grippometre.ospharm.org",
      src: "https://membre-server.leadersante-groupe.fr/img/grippometre.png",
    },
    {
      href: "https://membre.leadersante-groupe.fr/books",
      src: "https://membre-server.leadersante-groupe.fr/img/book.png",
    },
  ],
};

const buildEnv = () => {
  let e = dev;
  switch (process.env.REACT_APP_ENV) {
    case "development":
      e = dev;
      break;
    case "staging":
      e = staging;
      break;
    case "production":
      e = production;
      break;
  }

  return {
    ...e,
    apiUrl: e.backendUrl + "/api",
    newsleaderUrl: e.backendUrl + "/newsleader",
    teamMeetingUrl: e.backendUrl + "/teamMeeting",
    menuLinks: e.menuLinks,
    basketCookieTtl: 259200, // 3600 * 72 = 72h
  };
};

export const environment = buildEnv();
